import * as React from 'react';
import { Logos } from '@draftkings/event-page-widget-contracts';
import { Scoreboard, TeamLogoProps, DateCellProps } from '@draftkings/scoreboards';
import { ParsedEvent } from '../../contracts/parsers/IParser';
import { EventTeams } from '../EventTeams';
import { EventTimer } from '../EventTimer';
import './index.scss';

type EventHeaderProps = {
    event: ParsedEvent;
    leagueName: string;
    staticS3Host: string;
    logosMap: Logos | undefined;
    isTeamSwap: boolean;
    getNavLink: (routeDetails: React.PropsWithChildren<{ to: string }>) => JSX.Element;
    getTeamLogo: (props: TeamLogoProps) => JSX.Element;
    getDateCell: (props: DateCellProps) => JSX.Element;
    renderStrapline: () => JSX.Element;
    isSgpEnabled: boolean;
};

export const EventHeader: React.FC<EventHeaderProps> = ({
    leagueName,
    staticS3Host,
    logosMap,
    event,
    isTeamSwap,
    getNavLink,
    getTeamLogo,
    getDateCell,
    renderStrapline,
    isSgpEnabled
}) => {
    const scoreboardWrapperClassname = isSgpEnabled ? 'scoreboards-wrapper-sgp' : 'scoreboards-wrapper';
    return (
        <>
            {!event.scoreboardData && !isSgpEnabled && (
                <div className="ep-event-header" data-testid="ep-event-header">
                    <div className="ep-event-header__league-name" data-testid="ep-event-header-league-name">
                        {leagueName}
                    </div>
                    <div className="ep-event-header__body">
                        <div className="ep-event-header__body-teams">
                            <EventTeams
                                event={event}
                                logosMap={logosMap}
                                staticS3Host={staticS3Host}
                                isTeamSwap={isTeamSwap}
                            />
                        </div>
                        <div className="ep-event-header__body-timer" data-testid="ep-event-header-timer">
                            {event.status === 'STARTED' ? 'Live' : <EventTimer eventStartDate={event.startEventDate} />}
                        </div>
                    </div>
                </div>
            )}
            {!isSgpEnabled && renderStrapline()}
            {event.scoreboardData && (
                <div className={scoreboardWrapperClassname} data-testid="scoreboards-wrapper">
                    <Scoreboard
                        {...event.scoreboardData}
                        shouldSkipNavigation={true}
                        eventUrl={`/event/${event.id}`}
                        getDateCell={getDateCell}
                        getTeamLogo={getTeamLogo}
                        getNavLink={getNavLink}
                    />
                </div>
            )}
        </>
    );
};
