/**
 * References:
 * Check promise rejects section: https://developer.mozilla.org/en-US/docs/Web/API/AbortController
 * Check "AbortError" error name: https://developer.mozilla.org/en-US/docs/Web/API/DOMException#error_names
 */
export type AbortError = DOMException & {
    name: 'AbortError';
};

export function isAbortError(error: unknown): error is AbortError {
    return (error as AbortError).name === 'AbortError';
}
