import { BaseRetrieverOptions, IRetriever, IConditionManager, ICondition } from '@draftkings/widgets-core';
import { ContractTypes, SingleEventRequestResolver } from '@draftkings/dk-data-layer';
import { EventPageWidgetConfig } from '@draftkings/event-page-widget-contracts';
import { SBMessageBus, TrackEventFunction } from '@draftkings/sportsbook-common-contracts';

export type SportsbookData = {
    sports: Map<string, ContractTypes.Sport>;
    events: Map<string, ContractTypes.SportEvent>;
    markets: Map<string, ContractTypes.Market>;
    selections: Map<string, ContractTypes.Selection>;
    leagues: Map<string, ContractTypes.League>;
    subscriptionPartials: ContractTypes.SubscriptionPartial;
};

export interface IEventPageRetriever extends IRetriever<SportsbookData> {
    categoryId: string | undefined;
    eventId: string;
    setCategoryId: (categoryId: string) => void;
    resetCategory: () => void;
    loadData: () => void;
}

export type RetrieverOptions = BaseRetrieverOptions<SportsbookData> & {
    SportsbookEvent: ContractTypes.SportsbooksEventConstructor;
    singleEventRequestResolver: SingleEventRequestResolver;
    initialData: boolean;
    eventId: string;
    categoryName: string;
    categoryId?: string;
    serverData: SportsbookData | null;
    messageBus: SBMessageBus;
    throttleTimeout: number;
    condition: IConditionManager<typeof ConditionKeys> & ICondition;
    productConfig: EventPageWidgetConfig['productConfig'];
    trackEvent: TrackEventFunction;
    consumerVersion: string;
};

export type AddPayload = {
    leagues: ContractTypes.League[];
    events: ContractTypes.SportEventAdd[];
    markets: ContractTypes.Market[];
    selections: ContractTypes.Selection[];
    sports: ContractTypes.Sport[];
};

export type UpdatePayload = {
    events: ContractTypes.SportEventUpdate[];
    markets: ContractTypes.MarketUpdate[];
    selections: ContractTypes.SelectionUpdate[];
};

export type RemovePayload = {
    events: string[];
    markets: string[];
    selections: string[];
};

export const ConditionKeys = ['EventPageRetriever'] as const;
