import { getDefaultValue, disposer } from '@draftkings/sportsbook-common-utils';
import { BaseWidget, Condition } from '@draftkings/widgets-core';
import { IEventPageWidget, EventPageWidgetConfig } from '@draftkings/event-page-widget-contracts';
import { EventPageParser } from '../parser/EventPageParser';
import { EventPageRetriever } from '../retriever/EventPageRetriever';
import { EventPageState } from '../state';
import { IState } from '../contracts/state';
import { App } from '../components/App/App';
import { Controller } from '@draftkings/dk-tab-switcher';
import * as localizationLib from '@draftkings/sb-localization-lib';
import { getServerData } from '../retriever/helpers';
import { ContractTypes, SingleEventRequestResolver } from '@draftkings/dk-data-layer';
import { ConditionKeys } from '../contracts/retrievers/IRetriever';
import { EVENT_PAGE_WIDGET_ERROR, GLOBAL_CLIENT_FETCH_TIMEOUT } from '../helpers';

export class EventPageWidget extends BaseWidget implements IEventPageWidget {
    private dataProvider: IState;
    constructor(domId: string, options: EventPageWidgetConfig) {
        super(domId, (err) => options.trackEvent(EVENT_PAGE_WIDGET_ERROR, err));
        const condition = new Condition(ConditionKeys);
        const retriever = new EventPageRetriever({
            eventId: options.eventId,
            initialData: false,
            categoryId: options.categoryId,
            categoryName: options.categoryName,
            SportsbookEvent: options.dkDataLayer.SportsbookEvent,
            throttleTimeout: options.productConfig.eventPageWidgetConfig.throttleTimeout,
            defaultData: {
                sports: new Map<string, ContractTypes.Sport>(),
                events: new Map<string, ContractTypes.SportEvent>(),
                markets: new Map<string, ContractTypes.Market>(),
                selections: new Map<string, ContractTypes.Selection>(),
                leagues: new Map<string, ContractTypes.League>(),
                subscriptionPartials: {
                    entity: '',
                    query: '',
                    includeMarkets: ''
                }
            },
            serverData: getServerData(
                options.stadiumEventData,
                options.eventId,
                new Map(
                    options.stadiumEventData?.events
                        .flatMap((e) => e.subcategories)
                        .filter((s): s is ContractTypes.SubCategory => !!s)
                        .map((s) => [s.id, s])
                )
            ),
            messageBus: options.messageBus,
            condition,
            productConfig: options.productConfig,
            trackEvent: options.trackEvent,
            consumerVersion: options.consumerVersion,
            singleEventRequestResolver: new SingleEventRequestResolver({
                baseUrl: options.productConfig.sportsContentBff,
                requestTimeout: getDefaultValue(options.requestTimeout, GLOBAL_CLIENT_FETCH_TIMEOUT),
                consumerMetadata: {
                    clientFeature: 'event-page',
                    clientPage: 'Event',
                    clientVersion: options.consumerVersion,
                    widgetName: 'EventPageWidget',
                    widgetVersion: APP_VERSION
                }
            })
        });
        const isMobileFallback = getDefaultValue(options.isMobile, () => false);
        const parser = new EventPageParser({
            retriever,
            oddsStyle: options.oddsStyle,
            logos: options.logos,
            staticS3Host: options.staticS3Host,
            localizationLib,
            categoryId: options.categoryId,
            messageBus: options.messageBus,
            betslipSelections: options.betslipSelections,
            userKey: options.userKey,
            productConfig: options.productConfig,
            isMobile: isMobileFallback,
            onMobileToggleSelection: getDefaultValue(options.onMobileToggleSelection, disposer),
            featureFlags: options.featureFlags,
            trackEvent: options.trackEvent,
            source: options.source
        });
        this.dataProvider = new EventPageState({
            parser,
            messageBus: options.messageBus,
            tabSwitcherController: new Controller(),
            staticS3Host: options.staticS3Host,
            getNavLink: options.getNavLink,
            getTeamLogo: options.getTeamLogo,
            getDateCell: options.getDateCell,
            productConfig: options.productConfig,
            renderQuickSgp: options.renderQuickSgp,
            renderStats: options.renderStats,
            renderStrapline: options.renderStrapline,
            isMobile: isMobileFallback,
            tabName: options.tabName,
            hasStats: options.hasStats,
            isSgpEnabled: options.isSgpEnabled,
            logos: options.logos,
            condition,
            featureFlags: options.featureFlags
        });
        this.component = (
            <App
                dataProvider={this.dataProvider}
                logos={options.logos}
                trackEvent={options.trackEvent}
                renderLoader={options.renderLoader}
                consumerVersion={options.consumerVersion}
            />
        );
    }

    activate() {
        super.activate();
        this.dataProvider.activate();
        return this;
    }

    deactivate() {
        super.deactivate();
        this.dataProvider.deactivate();
        return this;
    }
}
